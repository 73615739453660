<template>
  <q-layout view="lHh Lpr lFf">
    <q-header>
      <q-toolbar>
        

        <q-toolbar-title>
          ZAF MATH GAME
        </q-toolbar-title>

      </q-toolbar>
    </q-header>

    

    <q-page-container>
      <HelloWorld />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'LayoutDefault',

  components: {
    HelloWorld
  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
