<template>
  <q-page class="flex flex-center">
    <div class="row">
      <div class="col">
        <div style="font-size: 300px">
          {{ displaystr }}
        </div>
        <div v-if="showprogress">
          <q-linear-progress
            stripe
            rounded
            size="20px"
            :value="progressval"
            color="warning"
            class="q-mt-sm"
          />
        </div>
      </div>
    </div>
  </q-page>
</template>

<style>
</style>

<script>
export default {
  name: "HelloWorld",
  mounted() {
    this.startQuestion();
  },
  methods: {
    startQuestion() {
      this.showprogress = true;
      this.getRandomQuestion();
      this.displaystr = this.questionstr;

      this.timer = setInterval(() => {
        if (this.progressval >= 1) {
          clearInterval(this.timer);
          this.progressval = 0;
          this.displaystr = this.resultstr;
          this.showprogress = false;

          setTimeout(() => {
            this.startQuestion()
          }, 2000)
        }
        this.progressval += 0.1;
      }, 500);
    },
    getRandomQuestion() {
      while(true) {
        var left = this.randomNumber(5, this.maxNum);
        var right = this.randomNumber(5, this.maxNum);
        this.resultstr = left * right;
        this.questionstr = left + " x " + right;
        if(left * right > 20)
           break;
      }
    },
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
  },
  data() {
    return {
      showprogress: true,
      maxNum: 10,
      progressval: 0,
      questionstr: "",
      resultstr: "",
      displaystr: "",
      timer: "",
      timer2: "",
    };
  },
};
</script>
